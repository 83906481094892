import { PLAYER_REGEX } from '~/utils/constants'

// State
export const state = () => ({
    loading: false,
    rejectAllApiRequests: false,
})
// Actions
export const actions = {
    async registration({ dispatch, rootState }, data) {
        if (data.player_id) {
            if (!PLAYER_REGEX.test(data.player_id)) return
        }
        const response = await dispatch('registrationForLao', data)
        return response
    },
    async registrationForLao(
        { commit, dispatch, rootState, rootGetters },
        data
    ) {
        commit('loading', true)
        const form = JSON.parse(JSON.stringify(data))
        form.language = rootGetters['base-settings/getWhiteLabelLocale']
        delete data.bankOptionSelection
        // Delete bankOptionSelection key, it's just telling us to know which bankOptionSelection the player is selecting
        const recaptchaToken = form.recaptchaToken
        delete form.recaptchaToken
        const rememberMe = form.rememberMe
        delete form.rememberMe

        // check referral types
        const referral = this.$cookie.get('referral')
        const previousUrl = this.$cookie.get('previous_url')
        const bannerCookieId = this.$cookie.get('banner_cookie_id')
        const bannerId = this.$cookie.get('banner_uuid') // This cookie is from /visit route
        const bannerUrl = this.$cookie.get('banner_url') // This cookie is from /visit route

        let referralId = null
        let referralType = null
        let referralCurrency = ''

        if (referral) {
            const { type, userId, currency } = referral
            referralId = userId
            referralCurrency = currency
            if (type === 'u') referralType = 'player'
            else if (type === 'a') referralType = 'agent'
        }

        // Logic
        // If the registered currency is not matched with the referral currency, then the player will be normal player
        // If they are matched, the player will be referral player under the player who generated the link;
        const isReferralAndRegisteredCurrencyMatched =
            referralCurrency?.toLowerCase() === data?.currency?.toLowerCase()

        const headers = {
            'Content-Type': 'Application/json',
            'auth-key': recaptchaToken,
        }
        if (
            referralId &&
            referralType &&
            isReferralAndRegisteredCurrencyMatched === true
        ) {
            headers.Cookies = `referral_id=${referralId}; referral_type=${referralType}`
        }
        if (previousUrl) {
            headers['X-AFFILIATE-URL'] = previousUrl
        }
        if (bannerCookieId) {
            headers['X-BANNER-ID'] = bannerCookieId
        }
        if (bannerId) {
            headers['X-BANNER-ID'] = bannerId
            if (bannerUrl) {
                headers['X-BANNER-URL'] = bannerUrl
            }
        }
        form.language = rootGetters['base-settings/getWhiteLabelLocale']
        const url = `/registration/option/${form.registrationOption}`

        form.country_code = rootGetters['settings/getCurrencyDetailByCurrency'](
            form.currency
        )?.country_code
        form.country = rootGetters['settings/getCurrencyDetailByCurrency'](
            form.currency
        )?.country_name
        if (!form.player_id) {
            delete form.player_id
        }

        if (form.registrationOption === 3) {
            delete form.bank_account_number
            delete form.bank_id
            delete form.bank_currency
        }
        delete form.registrationOption
        const redirect = rootState.themes.settings.isMobile ? '/games' : '/'

        const res = await this.$axios
            .post(url, form, {
                headers,
            })
            .then(async (response) => {
                if (response.status === 201) {
                    await this.$cookie.set('token', response.headers.token, {
                        path: '/',
                        maxAge: 60 * 60 * 3 - 60,
                    })

                    await this.$cookie.set('player', response.data)
                    this.$cookie.set('currency', response.data.currency)
                    await this.commit('player/setPlayer', response.data)
                    this.$cookie.set(
                        'country_code',
                        response?.data?.country_code
                    )

                    await this.dispatch('settings/toggleIsLogin', true, {
                        root: true,
                    })
                    await this.dispatch('player/totalBalance', true, {
                        root: true,
                    })
                    await dispatch('player/profile', true, {
                        root: true,
                    })

                    setRegisterRememberMe(this.$cookie, {
                        playerId: response.data.player_id,
                        phone: response.data.phone,
                        rememberMe,
                    })
                    // Success
                    commit('loading', false)
                    this.$toast.success(
                        this.getters['themes/language-string/tl'](
                            'registerSuccessful'
                        )
                    )
                    if (
                        isReferralAndRegisteredCurrencyMatched === true &&
                        referralId
                    ) {
                        await this.$cookie.remove('referral')
                    }
                    await this.$cookie.remove('previous_url')
                    await this.$cookie.remove('banner_cookie_id')
                    await this.$cookie.remove('banner_uuid')
                    await this.$cookie.remove('banner_url')
                    if (this.$cookie.get('banner_id')) {
                        this.$cookie.remove('banner_id')
                    }

                    dispatch('themes/player-bank/getBankPlayerAccount', true, {
                        root: true,
                    })
                    dispatch('player/getPlayerInviteAndEarnDomain', true, {
                        root: true,
                    })
                    // this.$router.push('/player/deposits')
                    return true
                }
            })
            .catch((e) => {
                commit('loading', false)
                // If the website is under maintenance during registration
                if (e?.response?.data?.website_maintenance) {
                    this.$router.go('/')
                }
                return false
            })

        return { redirect, res }
    },
    // login
    async login({ commit, dispatch, rootState }, form) {
        commit('loading', true)
        const defaultWebsiteDataFromDefaultAPI =
            rootState.settings.defaultWebsiteData

        const recaptchaToken = form.recaptchaToken
        delete form.recaptchaToken
        const rememberMe = form.rememberMe
        delete form.rememberMe

        const userPlatform = rootState.themes.settings.isMobile
            ? 'mobile'
            : rootState.app.isApp.detected
            ? 'app'
            : 'desktop'
        const headers = {
            'x-platform': userPlatform,
        }

        // auth-key will be always required for lao client
        // For international: check by /default api
        if (
            defaultWebsiteDataFromDefaultAPI.recaptcha_status ||
            !rootState.themes.settings.isInternational
        )
            headers['auth-key'] = recaptchaToken

        await this.$axios
            .post(`/players/login`, form, {
                headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    this.$cookie.set('token', response.headers.token, {
                        path: '/',
                        maxAge: 60 * 60 * 3 - 60,
                    })
                    dispatch(
                        'base-settings/updateLocaleAndSetCookie',
                        response.data.language,
                        { root: true }
                    )
                    this.$cookie.set('player', response.data)
                    this.$cookie.set('currency', response.data.currency)
                    this.$toast.success(
                        this.getters['themes/language-string/tl'](
                            'loginSuccessful'
                        )
                    )

                    if (form.player_id) {
                        if (rememberMe)
                            this.$cookie.set('un', form.player_id, {
                                path: '/',
                                maxAge: 60 * 60 * 24 * 30 - 60,
                            })
                        else this.$cookie.remove('un')
                    } else if (form.phone) {
                        if (rememberMe)
                            this.$cookie.set('pn', form.phone, {
                                path: '/',
                                maxAge: 60 * 60 * 24 * 30 - 60,
                            })
                        else this.$cookie.remove('pn')
                    }

                    this.$router.go('/')
                }
            })
            .catch((e) => {
                // If the website is under maintenance during login
                if (e?.response?.data?.website_maintenance) {
                    this.$router.go('/')
                }
                commit('loading', false)
            })
    },
    async logout({ commit, dispatch }) {
        try {
            if (this.$cookie.get('token')) {
                await this.$axios.put('/players/logout').finally(() => {
                    this.$cookie.remove('token')
                    dispatch('settings/toggleIsLogin', false, { root: true })
                    this.$toast.success(
                        this.getters['themes/language-string/tl'](
                            'logoutSuccessful'
                        )
                    )
                    this.$cookie.remove('player')

                    this.$router.push('/')
                    // Since the $router is not working inside this async, need to use JS build-in window
                    window.location.reload()
                })
            }
        } catch (error) {}
    },
}
// Mutations
export const mutations = {
    loading: (state, v) => {
        state.loading = v
    },
    setRejectAllApiRequests: (state, v) => {
        state.rejectAllApiRequests = v
    },
}

function setRegisterRememberMe(cookie, { playerId, phone, rememberMe }) {
    if (rememberMe) {
        cookie.set('un', playerId, {
            path: '/',
            maxAge: 60 * 60 * 24 * 30 - 60,
        })
        cookie.set('pn', phone, {
            path: '/',
            maxAge: 60 * 60 * 24 * 30 - 60,
        })
    } else {
        cookie.remove('un')
        cookie.remove('pn')
    }
}
